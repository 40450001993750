import { useEffect, useState } from 'react'

import { useIsomorphicLayoutEffect } from 'usehooks-ts'
import useWindowSize from './useWindowSize'

type UseLockedBodyOutput = [boolean, (locked: boolean) => void]

export function useLockedScroll(
    initialLocked = false,
    rootId = '___gatsby', // Default to `___gatsby` to not introduce breaking change
): UseLockedBodyOutput {
    const [locked, setLocked] = useState(initialLocked)
    const screenWidth = useWindowSize()


    // Do the side effect before render
    useIsomorphicLayoutEffect(() => {
        if (!locked) {
            return
        }

        // Save initial body style
        const originalOverflow = document.documentElement.style.overflow
        const originalPaddingRight = document.documentElement.style.paddingRight

        // Lock body scroll
        document.documentElement.style.overflow = 'hidden'

        // Get the scrollBar width
        //const root = document.getElementById("app") // or root
        const scrollBarWidth = screenWidth > 576 ? 10 : 0

        // Avoid width reflow
        if (scrollBarWidth) {
            document.documentElement.style.paddingRight = `${scrollBarWidth}px`
        }

        return () => {
            document.documentElement.style.overflow = originalOverflow

            if (scrollBarWidth) {
                document.documentElement.style.paddingRight = originalPaddingRight
            }
        }
    }, [locked])

    // Update state if initialValue changes
    useEffect(() => {
        if (locked !== initialLocked) {
            setLocked(initialLocked)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialLocked])

    return [locked, setLocked]
}

export default useLockedScroll