import Box from "..//Box";
import Button from "../buttons/Button";
import React, { Fragment, useEffect } from "react";
import FlexBox from "../FlexBox";
import Icon from "../icon/Icon";
import Typography, { H2 } from "../Typography";
import {
  DashboardNavigationWrapper,
  StyledDashboardNav,
} from "./DashboardStyle";
import AxiosService from "../../services/axios.service";
import { useAppContext } from "../../contexts/app/AppContext";
import { router } from '@inertiajs/react'
import useWindowSize from "../../hooks/useWindowSize";
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from "react-i18next";

const CustomerDashboardNavigation = ({ name, lastname }) => {

  const pathname = window.location.toString()
  const { state, dispatch } = useAppContext()
  const screenWidth = useWindowSize()
  const { t } = useTranslation()

  const singOut = () => () => {

    AxiosService
      .delete('/customer/logout')
      .then((response) => {

        if (response.data['status']) {
          dispatch(
            {
              'auth': {
                type: "SIGN_OUT",
                payload: {
                  token: '',
                  isAuth: false
                },
              }
            }
          );
          dispatch(
            {
              'cart':
              {
                type: "CHANGE_CART_AMOUNT_AFTER_ORDER",
                payload: {}
              }
            }
          );
          router.visit('/');
        }
      }).catch(
        function (error) {
          console.log(error)
        }
      )

  };

  const linkList = [
    {

      list: [
        {
          href: "/customer/account/profile",
          title: t("menu.profile"),
          iconName: "home",
          count: null,
        },
        {
          href: "/customer/account/addresses",
          title: t("menu.addresses"),
          iconName: "pin",
          count: null,
        },
        {
          href: "/customer/account/orders",
          title: t("menu.orders"),
          iconName: "bag",
          count: null,
        },
        {
          href: "/customer/account/change-password",
          title: t("menu.changepassword"),
          iconName: "heart",
          count: null,
        },
      ],
    },
  ];

  return (
    <DashboardNavigationWrapper height='100%' justifyContent='flex-start' width='80%' m='auto' mt={5} className="user-menu-sidenav" px="0px" pb="1.5rem" >

      {linkList.map((item, ind) => (
        <Fragment key={ind}>
          {/* <Typography p="26px 30px 1rem" color="text.muted" fontSize="12px">
            {item.title}
          </Typography> */}

          {item.list.map((item) => (
            <StyledDashboardNav
              isCurrentPath={pathname.includes(item.href)}
              href={item.href}
              key={item.title}


            >
              <FlexBox alignItems="center" className="dashboard-item-wrapper"  >
                <Box className="dashboard-nav-icon-holder">
                  <Icon variant="small" defaultcolor="white" mr="10px" className='dashboard-icon'>
                    {item.iconName}
                  </Icon>
                </Box>
                <H2>{item.title}</H2>
              </FlexBox>
              <span>{item.count}</span>
            </StyledDashboardNav>
          ))}
        </Fragment>
      ))}

      <Box mt={0} className="signout-wrapper" height={screenWidth > 1024 ? '69px' : '40px'} alignItems="flex-start" >
        <Button
          borderRadius={10}
          backgroundColor='#00000087'
          className="signout-button"
          onClick={singOut()}
          fullwidth
          border={'unset'}
        >
          <Box display="flex" className='signout-button-text'>
            <LogoutIcon sx={{ ml: 0.3 }} />
            <Typography fontSize={screenWidth > 1980 ? '1.4vw !important' : 'inherit'} > {t("menu.logout")} </Typography>
          </Box>
        </Button>
      </Box>
    </DashboardNavigationWrapper>
  );
};



export default CustomerDashboardNavigation;
