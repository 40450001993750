
import React from "react";
import styled from "styled-components";
import { getTheme } from "../../utils/utils";
import Box from "../Box";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Typography, { Span } from "../Typography";
import Grid from "../grid/Grid";
import Icon from "../icon/Icon";
import StyledFooter from "./FooterStyle";
import imgSrc from "./footer_bg.svg";
import { Link } from "@inertiajs/react";
import { useAppContext } from "../../contexts/app/AppContext";
import { useTranslation } from 'react-i18next';


const StyledLink = styled.a`
  position: relative;
  display: block;
  padding: 0.3rem 0rem;
  color: ${getTheme("colors.gray.500")};
  cursor: pointer;
  border-radius: 4px;
  :hover {
    color: ${getTheme("colors.gray.100")};
  }
`;

const StyledGrid = styled(Grid)`

@media only screen and (max-width: 768px) {
  flex-direction : column;
  align-items : center;
  gap : 3rem;
}
`

const StyledBox = styled(Box)`
  white-space: nowrap;
  line-height : 2;

  @media only screen and (max-width: 450px) {

  }

`

const Footer: React.memo = () => {

  const { state, dispatch, footerVisible } = useAppContext();
  const { auth } = state
  const { t } = useTranslation()

  return (
    footerVisible && <footer>
        <StyledFooter  className='footerWrapper'>

            <Container className="footer-container" p="1rem">
                <Box py="5rem" overflow="hidden">
                    <StyledGrid container spacing={6} justifyContent='space-between'>
                        {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                  <a>
                    <Image
                      mb="1.25rem"
                      src={logo}
                      alt="logo"
                    />
                  </a>


              </Grid> */}

                        <Grid item lg={3} md={6} sm={6} xs={12} xl={4.5} className={'firstBlock alignFooter'}>
                            <Typography className='block-title'>
                                {t('footer.contact')}
                            </Typography>

                            <StyledBox>
                                <Typography fontWeight="100" className='block-item'>
                                    {t('footer.address')}
                                </Typography>
                                <FlexBox fontWeight="100" alignItems={'center'} className={'innerInfo justifyCenter'}>

                                    <Icon size="20px" defaultcolor="auto">
                                        {'mail'}
                                    </Icon>
                                    <a href="mailto:info@soma.com.gr">
                                        <Span className='block-item' fontWeight="100">info@soma.com.gr</Span>
                                    </a>
                                </FlexBox>
                                <FlexBox fontWeight="100" alignItems={'center'} className={'innerInfo justifyCenter'}>
                                    <Icon size="20px" defaultcolor="auto">
                                        {'phone'}
                                    </Icon>
                                    <a href="tel:+30 25410 32442">
                                        <Span className='block-item' fontWeight="100">+30 25410 32442</Span>
                                    </a>
                                </FlexBox>
                            </StyledBox>
                        </Grid>

                        <Grid item lg={3} md={6} sm={6} xs={12} className={'secondBlock alignFooter'}>
                            <Typography className='block-title'
                            >
                                {t('footer.account')}
                            </Typography>

                            {/* <div>
                  {customerCareLinks.map((item, ind) => (
                    <StyledLink>{item}</StyledLink>
                  ))}
                </div> */}
                            {
                                auth.isAuth &&
                                <Link href="/customer/account/profile">
                                    <Typography className='block-item' py="0.3rem">
                                        {t('footer.account')}
                                    </Typography>
                                </Link>
                            }

                            {
                                !auth.isAuth &&
                                <Link href='/customer/login'>
                                    <Typography className='block-item' py="0.3rem">
                                        {t('footer.connect')}
                                    </Typography>
                                </Link>
                            }


                            <Link href='/checkout/cart'>
                                <Typography className='block-item' py="0.3rem">
                                    {t('footer.cart')}
                                </Typography>
                            </Link>
                        </Grid>

                        <Grid item lg={3} md={6} sm={6} xs={12} className={'alignFooter'}>
                            <Typography className='block-title'
                            >
                                {t('footer.contact')}
                            </Typography>
                            <Link href='/shipping-policy'>
                                <Typography className='block-item' py="0.3rem">
                                    {t('footer.shipping')}
                                </Typography>
                            </Link>
                            <Link href='/payment-policy'>
                                <Typography className='block-item' py="0.3rem">
                                    {t('footer.payment')}
                                </Typography>
                            </Link>
                            <Link href='/privacy-policy'>
                                <Typography className='block-item' py="0.3rem">
                                    {t('footer.privacy')}
                                </Typography>
                            </Link>
                            <Link href='/return-policy'>
                                <Typography className='block-item' py="0.3rem">
                                    {t('footer.return')}
                                </Typography>
                            </Link>
                            <Link href='/instructions'>
                                <Typography className='block-item' py="0.3rem">
                                    SOMA.COM.GR Application
                                </Typography>
                            </Link>
                            <Link href='/company'>
                                <Typography className='block-item' py="0.3rem">
                                    {t('footer.company')}
                                </Typography>
                            </Link>

                        </Grid>
                    </StyledGrid>
                </Box>

            </Container>
            <Box width={'90%'} textAlign='center' color={'white'} borderBottom='solid 1px white' margin={'auto'}>

            </Box>

            <Box className="afterFooter">
                <a target="_blank" href="https://lioncode.gr">
                    {t('footer.websitescreate')} <br></br>
                </a>
                <Span fontWeight={600}>Lioncode Your It People</Span>

            </Box>

        </StyledFooter>

    </footer>
  );
};

const aboutLinks = [
    "Careers",
    "Our Stores",
    "Our Cares",
    "Terms & Conditions",
    "Privacy Policy",
];

const customerCareLinks = [
    "Help Center",
    "How to Buy",
    "Track Your Order",
    "Corporate & Bulk Purchasing",
    "Returns & Refunds",
];

const iconList = [
    {iconName: "facebook", url: "https://www.facebook.com/UILibOfficial"},
    {iconName: "twitter", url: "/" },
  {
    iconName: "youtube",
    url: "https://www.youtube.com/channel/UCsIyD-TSO1wQFz-n2Y4i3Rg",
  },
  { iconName: "google", url: "/" },
  { iconName: "instagram", url: "/" },
];

export default Footer;
