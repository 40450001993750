import Footer from "../footer/Footer";
import Header from "../header/Header";
import MobileNavigationBar from "../mobile-navigation/MobileNavigationBar";
import Sticky from "../sticky/Sticky";
import React, { ReactNode, useLayoutEffect } from "react";
import StyledAppLayout from "./AppLayoutStyle";
import MetaHead from "../../Pages/MetaHead/MetaHead";
import { GlobalStyles } from "../../utils/globalStyles";
import Snackbars from "../Snackbars";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from "framer-motion";
import { useAppContext } from "../../contexts/app/AppContext";
import { useEffect } from "react";

//import loadable from '@loadable/component'


//const Footer = loadable(() => import('../footer/Footer'));
//const Header = loadable(() => import('../header/Header'));

type Props = {
  title?: string;
  navbar?: ReactNode;
  seo?: [],
  showBgImage?: boolean,
  url?: URL
}

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "@media only screen and (min-width : 1980px)": {
            width: '1.3vw',
            height: '1.3vw'
          }
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "@media only screen and (min-width : 1980px)": {
            fontSize: '0.8vw'
          }
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "@media only screen and (min-width : 1980px)": {
            fontSize: '0.8vw'
          }
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "@media only screen and (min-width : 1980px)": {
            padding: '0.8vw'
          }
        },
      },
    },
  },
});

const AppLayout: React.memo<Props> = ({
  children,
  navbar,
  seo,
  showBgImage,
  ulrBg
}) => {

  const { isMounted, imgsLoaded } = useAppContext()


  useEffect(() => {
    history.scrollRestoration = 'auto';
  }, [])

  return (

    <>
      <Sticky fixedOn={0}>
        <Header />
      </Sticky>
      <ThemeProvider theme={theme}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            opacity: { duration: 1 },
          }}
        >
          <MetaHead seo={seo} />
          <GlobalStyles ulrBg={ulrBg} />

          <StyledAppLayout className={showBgImage !== undefined ? 'background-product-page' : ' '} >

            {/* <Topbar /> */}



            {navbar && <div >{navbar}</div>}
            {!navbar ? (
              <div >{children}</div>
            ) : (
              children
            )}

            {/* <MobileNavigationBar /> */}

            {isMounted && imgsLoaded && <Footer />}
           
            <Snackbars />
          </StyledAppLayout>
        </motion.div>
      </ThemeProvider>
    </>

  )
}


export default AppLayout
