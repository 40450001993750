import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import StyledSticky from "./SickyStyle";
import useScrollDirection from "../../hooks/useScrollDirection";

export interface StickyProps {
  fixedOn: number;
  containerRef?: { current: any };
  children?: ReactElement;
  onSticky?: (isFixed: boolean) => void;
  notifyOnScroll?: (hasReachedPosition: boolean) => void;
  notifyPosition?: number;
}

const Sticky: React.memo<StickyProps> = ({
  fixedOn,
  containerRef,
  children,
  notifyPosition,
  notifyOnScroll,
  onSticky,
}) => {
  const [fixed, setFixed] = useState(false);
  const [parentHeight, setParentHeight] = useState(0);
  const elementRef = useRef(null);
  const positionRef = useRef(null);



  // const scrollListener = useCallback(() => {
  //   if (!window) return;

  //   let distance = window.pageYOffset - positionRef.current;

  //   if (containerRef?.current) {
  //     let containerDistance =
  //       containerRef.current.offsetTop +
  //       containerRef.current?.offsetHeight -
  //       window.pageYOffset;

  //     if (notifyPosition && notifyOnScroll) {
  //       notifyOnScroll(
  //         distance <= notifyPosition && containerDistance > notifyPosition
  //       );
  //     }
  //     return setFixed(distance <= fixedOn && containerDistance > fixedOn);
  //   }

  //   if (notifyPosition && notifyOnScroll) {
  //     notifyOnScroll(distance >= notifyPosition);
  //   }

  //   let isFixed = distance >= fixedOn;
  //   setFixed(isFixed);
  // }, []);

  // useEffect(() => {
  //   if (!window) return;

  //   window.addEventListener("scroll", scrollListener);
  //   window.addEventListener("resize", scrollListener);
  //   return () => {
  //     window.removeEventListener("scroll", scrollListener);
  //     window.removeEventListener("resize", scrollListener);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!positionRef.current) {
  //     positionRef.current = elementRef.current?.offsetTop;
  //   }
  //   setParentHeight(elementRef.current?.offsetHeight || 0);
  // }, [elementRef.current, children]);

  // useEffect(() => {
  //   if (onSticky) onSticky(fixed);
  // }, [fixed]);

  const scrollDirection = useScrollDirection();
  
  useEffect(() => {
    if (scrollDirection === "down") {
      setFixed(false);
    } else if (scrollDirection === "up") {
      setFixed(true);
    }
  }, [scrollDirection]);
  

  return (
    <StyledSticky
      componentPosition={positionRef.current}
      componentHeight={parentHeight}
      fixedOn={fixedOn}
      fixed={fixed}
      ref={elementRef}
    >
      {React.cloneElement(children, { isFixed: fixed })}
    </StyledSticky>
  );
};

export default Sticky;
