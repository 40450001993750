import styled from "styled-components";
import Box from "../Box";
import imgSrcLeft from "./footer-bg-left.svg";
import imgSrcRight from "./footer-bg-right.svg";

const StyledFooter = styled(Box)`
    background-color: black;
    background-image: url(${imgSrcLeft}), url(${imgSrcRight});
    background-position: left top, right top;
    background-size: 150px 100%, 500px 100%; /* Initial size */
    background-repeat: no-repeat;
    position: relative;

    .firstBlock {
        width: 25% !important;
    }
    .secondBlock {

    }
    .innerInfo {
        gap: 1rem;
    }
    .block-title {
        font-size: 25px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 1.25rem;
    }
    .block-item {
        font-size: 20px;
        font-weight: 100;
    }
    .afterFooter {
        box-shadow: none;
        cursor: unset;
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center;
        color: white;
        font-weight: 300;
    }

    /* Media queries for responsiveness */
    @media (max-width: 1200px) {
        background-size: 150px 100%, 500px 100%;
    }

    @media (max-width: 992px) {
        background-size: 150px 100%, 0% 0%;
    }

    @media (max-width: 768px) {
        background-size: 0px 100%, 0px 0%;
    }

    @media (max-width: 576px) {

        background-image: url(${imgSrcLeft}), url(${imgSrcRight});
    );
        background-position: left top, center bottom;
        background-size: 0px 0%, 0% 0%;
    }
}

@media only screen and (max-width:768px) {
    .firstBlock {
        width: 50% !important;
    }

    .alignFooter {
        width: 100% !important;
        text-align: center;
    }

    .justifyCenter {
        justify-content: center;
    }
}


@media only screen and (max-width:428px) {
    .firstBlock {
        width: 100% !important;
    }
}

`

export default StyledFooter