import React, { useEffect, useState } from 'react';
import { Link } from '@inertiajs/react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useAppContext } from '../contexts/app/AppContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


const theme = createTheme({
    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    borderRadius: 7,
                    alignItems: 'center',
                    "@media only screen and (min-width : 1980px)": {
                        fontSize: '0.7vw;',
                        'svg': {
                            fontSize: '1vw',
                        }
                    },
                    "svg:hover": {
                        transition: '0.250s ease',
                        transform: 'scale(1.3)',
                        color: '#b95757'
                    }
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '@media only screen and (min-width : 1980px)': {
                        width: '15vw'
                    }
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    marginTop : 104
                },
            },
        },
    },
});

const Snackbars = () => {

    const [isIphone, setIsIphone] = useState<boolean>(false);

    useEffect(() => {
        const platform = window.navigator.userAgent;
        setIsIphone(platform.includes('iPhone'));
    }, []);

    const { openSnackCart, setOpenSnackCart, openSnackRemove, setOpenSnackRemove, openSnackUpdate, setOpenSnackUpdate,
        openSnackError, setOpenSnackError, openSnackProfileUpdate, setOpenSnackProfileUpdate, openSnackProfileCreate,
        setOpenSnackProfileCreate, openSnackCreateAcc, setOpenSnackCreateAcc, setOpenSnackAFMRegisterNotFound, openSnackAFMRegisterNotFound,
        openSnackContact, setOpenSnackContact, openSnackEmptyCart, setOpenSnackEmptyCart, showMessageSnack, setShowMessageSnack, alert, setAlert, message, setMessage,
    } = useAppContext()

    const handleCloseSnackCart = () => {

        setOpenSnackCart(false)
        setOpenSnackRemove(false)
        setOpenSnackUpdate(false)
        setOpenSnackError(false)
        setOpenSnackProfileUpdate(false)
        setOpenSnackProfileCreate(false)
        setOpenSnackCreateAcc(false)
        setOpenSnackAFMRegisterNotFound(false)
        setOpenSnackContact(false)
        setOpenSnackEmptyCart(false)
        setShowMessageSnack(false)
        setAlert(false)
        setMessage('');
    }

    const { t } = useTranslation()

    return (

        <ThemeProvider theme={theme}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackCart}
                autoHideDuration={isIphone ? 2500 : 5000}
                onClose={(event, reason) => handleCloseSnackCart(event, reason)}
                sx={{ mt: 13 }}
            >
                <Alert
                    onClose={() => handleCloseSnackCart()}
                    sx={{ backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}
                >
                    {t("snackbar.add")} <Link style={{ textDecoration: 'underline' }} href='/checkout/cart'>{t("snackbar.cart1")}</Link>
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackUpdate}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={(event, reason) => handleCloseSnackCart(event, reason)}
                sx={{ mt: 13, borderRadius: '7px' }}
            >
                <Alert
                    onClose={() => handleCloseSnackCart()}
                    sx={{ backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}
                >
                    {t("snackbar.update")} <Link style={{ textDecoration: 'underline' }} href='/checkout/cart'>{t("snackbar.cart2")}</Link>
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackRemove}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={() => handleCloseSnackCart()}
                sx={{ mt: 13 }}
            >
                <Alert
                    onClose={() => handleCloseSnackCart()}
                    sx={{ backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}
                >
                    {t("snackbar.remove")}&nbsp;
                    <Link style={{ textDecoration: 'underline' }} href='/checkout/cart'>
                        {t("snackbar.cart1")}
                    </Link>
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackError}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={() => handleCloseSnackCart()}
            >
                <Alert
                    severity="error"
                    onClose={() => handleCloseSnackCart()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {t("snackbar.wrong")} <br></br>{t("snackbar.tryagain")}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackProfileUpdate}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={() => handleCloseSnackCart()}
            >
                <Alert
                    severity="success"
                    onClose={() => handleCloseSnackCart()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {t("snackbar.succupdate")}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackProfileCreate}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={() => handleCloseSnackCart()}
            >
                <Alert
                    severity="success"
                    onClose={() => handleCloseSnackCart()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {t("snackbar.succreate")}
                </Alert>
            </Snackbar>


            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackCreateAcc}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={() => handleCloseSnackCart()}
            >
                <Alert
                    severity="success"
                    onClose={() => handleCloseSnackCart()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {t("snackbar.register")}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackAFMRegisterNotFound}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={() => handleCloseSnackCart()}
            >
                <Alert
                    severity="error"
                    onClose={() => handleCloseSnackCart()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {t("snackbar.vat")}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackContact}
                autoHideDuration={10000}
                onClose={() => handleCloseSnackCart()}
            >
                <Alert
                    severity="success"
                    onClose={() => handleCloseSnackCart()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {t("snackbar.contact")}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackEmptyCart}
                autoHideDuration={10000}
                onClose={() => handleCloseSnackCart()}
                sx={{ mt: 13, borderRadius: '7px' }}
            >
                <Alert
                    severity="success"
                    onClose={() => handleCloseSnackCart()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {t("snackbar.emptycart")}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showMessageSnack}
                autoHideDuration={isIphone ? 1500 : 5000}
                onClose={() => setShowMessageSnack()}
            >
                <Alert
                    severity={alert ? "error" : "success"}
                    onClose={() => setShowMessageSnack()}
                    sx={{ width: 350, backgroundColor: 'black', border: 'solid 1px white', color: 'white' }}>
                    {message}
                </Alert>
            </Snackbar>


        </ThemeProvider>

    )
}

export default Snackbars