import styled from "styled-components";
import { getTheme } from "../../utils/utils";
import { useAppContext } from "../../contexts/app/AppContext";

const StyledAppLayout = styled.div`
  
  color : white;
  background-color: #212121;

  .header-container {
    box-shadow: ${getTheme("shadows.regular")};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px #1b1717 inset !important;
    -webkit-text-fill-color: white !important;
    caret-color : white;

  }

  label{
    z-index : 1 ;
    position : relative;
  }
  
  
  .background-product-page{
    position : absolute;
    top :0;
    width : 100%;
    height : 100%;
    background-image : url('https://wallpaperaccess.com/full/2957951.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: soft-light;
  }


  @media only screen and (max-width : 1070px ){
    .background-product-page{
      height:980px;
    }
  }

  @media only screen and (max-width : 915px ){
    .background-product-page{
      height:1170px;
    }
  }

  @media only screen and (max-width : 520px ){
    .background-product-page{
      height:849px;
    }
  }

`;

export default StyledAppLayout;
